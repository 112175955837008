<template>
  <b-navbar
    sticky
    toggleable="lg"
    type="light"
    :variant="scrollPosition > 50 ? 'white' : 'light'"
  >
    <b-navbar-brand>
      <a href="https://www.4DCorridor.org" target="_blank">
        <img
        src="@/assets/images/logo.png"
        alt="logo"
        style="max-width: 100px; height: auto"
      />
      </a>
      
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!--nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item :href="button.url" v-for="(button, index) in buttons" :key="index">
          <b-button :variant="button.variant || 'text'">{{
            button.text
          }}</b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
/*eslint no-unused-vars: "error"*/
import url from '@/helpers/url'
import service from '@/helpers/service'
export default {
  name: "TopBar",

  props: ["scrollPosition"],

  data: () => ({
    buttons: [
      { text: "Home",url:"#home" },
      { text: "About", url: '#about'},
      { text: "Partners", url: "#partners" },
      { text: "Marketplace",url:'#marketplace' },
      { text: "Register", variant: "primary", url:`${url()}/accounts/register`},
      { text: "Login", url:`${url()}/accounts/login?service=${service()}` },
    ],
  }),
};
</script>
